<template>
  <div class="full-page">
    <v-container v-if="isCanConfirmPayment">
      <v-row v-bind="{ justify: 'center' }">
        <v-col cols="12" md="8" lg="6">
          <v-row v-bind="{ justify: 'center' }">
            <v-col cols="12" class="text-center">
              <h1 class="text-3xl">
                ยืนยันการชำระเงิน
              </h1>
              <p class="text-sm mb-0">
                กรุณาตรวจสอบข้อมูล
                และทำการกรอกข้อมูลการชำระเงินของลูกค้า
              </p>
            </v-col>

            <v-col cols="12" class="text-center">
              <div class="qr-code-box">
                <qrcode-vue :value="deepLink" size="120"></qrcode-vue>
              </div>
              <div class="text-sm">
                QR Code ยืนยันการชำระเงิน
                <br />
                สำหรับพนักงานเท่านั้น!
              </div>
            </v-col>

            <v-col cols="12">
              <OrderCard
                v-if="!!order"
                :order="order.data"
                :is-show-shipping="false"
                :is-show-view-detail="false"
                :is-show-bundle-products="true"
              />
            </v-col>

            <v-col cols="12">
              <h2 class="text-xl">
                ยืนยันการชำระเงิน
              </h2>
              <p class="text-sm mb-0">
                กรอกข้อมูลการชำระเงินของลูกค้า
              </p>
            </v-col>

            <!-- order_type -->
            <v-col cols="12">
              <div>รายการคำสั่งซื้อ</div>
              <v-radio-group
                v-model="form.order_type"
                :hide-details="!$v.form.order_type.$error"
                :error="$v.form.order_type.$error"
                :error-messages="handleErrorMessage('order_type')"
                ref="order_type"
                data-pptr="order_type"
              >
                <v-radio
                  v-for="(item, index) in order_types"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                  class="mb-3"
                />
              </v-radio-group>
            </v-col>

            <!-- isCanAddOntopDiscount -->
            <v-col v-if="isCanAddOntopDiscount" cols="12">
              <div class="ontop-discount-container">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.ontop_discount"
                      :hide-details="!$v.form.ontop_discount.$error"
                      :error="$v.form.ontop_discount.$error"
                      :error-messages="
                        handleErrorMessage('ontop_discount')
                      "
                      :color="colorBnn['color-bnn']"
                      background-color="white"
                      type="number"
                      data-pptr="ontop_discount"
                      label="ส่วนลดจากโปรโมชั่นเบอร์ทรู"
                      placeholder="กรอกจำนวนเงินส่วนลด"
                      outlined
                      inputmode="decimal"
                      oninput="this.value=this.value.replace(/[^\d]+/, '')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="form.advance_payment"
                      :hide-details="!$v.form.advance_payment.$error"
                      :error="$v.form.advance_payment.$error"
                      :error-messages="
                        handleErrorMessage('advance_payment')
                      "
                      :color="colorBnn['color-bnn']"
                      background-color="white"
                      type="number"
                      data-pptr="advance_payment"
                      label="ชำระล่วงหน้า"
                      placeholder="จำนวนเงิน"
                      outlined
                      inputmode="decimal"
                      oninput="this.value=this.value.replace(/[^\d]+/g, '')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="form.advance_payment_optional"
                      :error="$v.form.advance_payment_optional.$error"
                      :color="colorBnn['color-bnn']"
                      hide-details
                      background-color="white"
                      data-pptr="advance_payment_optional"
                      label="Advance Payment (ถ้ามี)"
                      placeholder="จำนวนเงิน"
                      outlined
                      oninput="this.value=this.value.replace(/[^\d]+/g, '')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form.rc_code"
                      :hide-details="!$v.form.rc_code.$error"
                      :error="$v.form.rc_code.$error"
                      :error-messages="handleErrorMessage('rc_code')"
                      :color="colorBnn['color-bnn']"
                      background-color="white"
                      data-pptr="rc_code"
                      label="RC"
                      placeholder="กรอก RC"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-col>

            <!-- isCanAddVoucher -->
            <v-col v-if="isCanAddVoucher" cols="12">
              <ConfirmPaymentApplyVoucher
                v-model="form.voucher_code"
                :is-error="$v.form.voucher_code.$error"
                :error-messages="handleErrorMessage('voucher_code')"
                :isCanAddVoucher="isCanAddVoucher"
              />
            </v-col>

            <!-- pay_at_store_choice_id -->
            <v-col cols="12">
              <v-select
                v-model="form.pay_at_store_choice_id"
                :items="pay_at_store_choices"
                :color="colorBnn['color-bnn']"
                :loading="isEdcLoading"
                :hide-details="!$v.form.pay_at_store_choice_id.$error"
                :error="$v.form.pay_at_store_choice_id.$error"
                :error-messages="
                  handleErrorMessage('pay_at_store_choice_id')
                "
                item-text="title"
                item-value="id"
                data-pptr="pay_at_store_choice_id"
                label="รูปแบบการชำระเงิน"
                placeholder="เลือกรูปแบบการชำระเงิน"
                outlined
              ></v-select>
            </v-col>

            <!-- card_id -->
            <v-col v-if="isEdcCreditCard || isEdcQr" cols="12">
              <v-select
                v-model="form.card_id"
                :items="cardTypesByPayAtStoreChoice"
                :color="colorBnn['color-bnn']"
                :hide-details="!$v.form.card_id.$error"
                :error="$v.form.card_id.$error"
                :error-messages="handleErrorMessage('card_id')"
                item-text="card_name"
                item-value="card_id"
                ref="card_id"
                data-pptr="card_id"
                label="ประเภทบัตร"
                placeholder="เลือกประเภทบัตร"
                outlined
              />
            </v-col>

            <!-- edc_terminal *only payment_type == "edc_credit_card | edc_qr" -->
            <v-col v-if="isEdcCreditCard || isEdcQr" cols="12">
              <v-autocomplete
                v-model="form.edc_terminal"
                :items="edcCode"
                :color="colorBnn['color-bnn']"
                :hide-details="!$v.form.edc_terminal.$error"
                :error="$v.form.edc_terminal.$error"
                :error-messages="handleErrorMessage('edc_terminal')"
                :loading="isEdcLoading"
                data-pptr="edc_terminal"
                item-text="edc_name"
                item-value="tid"
                label="EDC Code"
                placeholder="เลือก EDC Code"
                outlined
              ></v-autocomplete>
            </v-col>

            <!-- edc_credit_card_no *only payment_type == "edc_credit_card" -->
            <v-col v-if="isEdcCreditCard" cols="12">
              <v-text-field
                v-model="form.edc_credit_card_no"
                :color="colorBnn['color-bnn']"
                :hide-details="!$v.form.edc_credit_card_no.$error"
                :error="$v.form.edc_credit_card_no.$error"
                :error-messages="
                  handleErrorMessage('edc_credit_card_no')
                "
                data-pptr="edc_credit_card_no"
                label="เลขบัตรเครดิต 4 ตัวท้าย"
                outlined
                placeholder="เลขบัตรเครดิต 4 ตัวท้าย"
                oninput="this.value=this.value.slice(0,4).replace(/[^\d]+/g, '')"
              ></v-text-field>
            </v-col>

            <!-- edc_comment *only payment_type == "edc_credit_card | edc_qr" -->
            <!-- <v-col v-if="isEdcCreditCard || isEdcQr" cols="12">
              <v-text-field
                v-model="form.edc_comment"
                :color="colorBnn['color-bnn']"
                :hide-details="!$v.form.edc_comment.$error"
                :error="$v.form.edc_comment.$error"
                :error-messages="handleErrorMessage('edc_comment')"
                data-pptr="edc_comment"
                outlined
                label="หมายเหตุของบัตรเครดิต"
                placeholder="หมายเหตุของบัตรเครดิต"
              ></v-text-field>
            </v-col> -->

            <v-col cols="12">
              <v-textarea
                v-model="form.remark"
                :color="colorBnn['color-bnn']"
                :hide-details="!$v.form.remark.$error"
                :error="$v.form.remark.$error"
                :error-messages="handleErrorMessage('remark')"
                data-pptr="remark"
                label="คอมเมนต์บิล"
                outlined
                placeholder="คอมเมนต์บิล"
                auto-grow
                rows="3"
                max-rows="6"
                oninput="this.value=this.value.slice(0,100)"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <section class="action-confirm-container">
      <v-container>
        <v-row v-bind="{ justify: 'center', 'no-gutters': true }">
          <v-col cols="12" md="8" lg="6" class="action-confirm">
            <div class="w-full">
              <v-row v-bind="{ justify: 'between', align: 'center' }">
                <v-col>
                  <div class="price-box">
                    <div class="text-price-md">
                      ยอดชำระ
                    </div>
                    <span
                      class="text-price-lg  text-right text-green"
                    >
                      {{ paidAmount | currency2 }}
                    </span>
                    <div
                      v-if="totalDiscount > 0"
                      class="text-xs d-block d-sm-inline"
                    >
                      (หักส่วนลดแล้ว
                      {{ totalDiscount | currency2 }})
                    </div>
                  </div>
                </v-col>
                <v-col cols="auto">
                  <ConfirmPaymentApprovalCode
                    ref="confirmPaymentApprovalCode"
                    :isRequireApprovalCode="
                      isEdcCreditCard || isEdcQr
                    "
                    :is-loading="isSubmitLoading"
                    :disabled="isOrderLoading"
                    @confirm-payment="handleConfirmPayment"
                    @submit="handleSubmit"
                  />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import colorBnn from '@/config/color'
import { validationMixin } from 'vuelidate'
import {
  required,
  requiredIf,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'
import { ORDER_STATUS } from '@/config/order'

export default {
  name: 'ConfirmPayment',

  mixins: [validationMixin],

  components: {
    OrderCard: () => import('@/components/bnn/OrderCard'),
    QrcodeVue: () => import('qrcode.vue'),
    ConfirmPaymentApprovalCode: () =>
      import('@/components/bnn/ConfirmPaymentApprovalCode'),
    ConfirmPaymentApplyVoucher: () =>
      import('@/components/bnn/ConfirmPaymentApplyVoucher'),
  },

  computed: {
    ...mapState({
      order: state => state.order,
      isOrderLoading: state => state.order.isLoading,
      isSuccess: state => state.order.submit?.isSuccess,
      isSubmitLoading: state => state.order.submit?.isLoading,

      // EDC
      edcCode: state => state.paymentType.edc.code || [],
      ecdChoices: state => state.paymentType.edc.choices || [],
      isEdcLoading: state => state.paymentType.edc.isLoading,

      order_types: state => state.paymentType.edc.order_types,
      pay_at_store_choices: state =>
        state.paymentType.edc.pay_at_store_choices,
      card_types: state => state.paymentType.edc.card_types,

      voucher: state => state.paymentType.voucher,
      isVoucherValid: state =>
        state.paymentType.voucher?.data?.is_valid || false,
    }),

    cardTypesByPayAtStoreChoice() {
      return this.$store.getters[
        'paymentType/getCardTypesByPayAtStoreChoiceId'
      ](this.form.pay_at_store_choice_id)
    },

    paymentTypeSelected() {
      return this.$store.getters[
        'paymentType/getPaymentTypeByPayAtStoreChoiceId'
      ](this.form.pay_at_store_choice_id)
    },

    isCanAddOntopDiscount() {
      return this.form.order_type === 'with_plan'
    },

    isCanAddVoucher() {
      return this.form.order_type === 'with_tradein'
    },

    isEdcCreditCard() {
      return (
        this.paymentTypeSelected?.payment_type === 'edc_credit_card'
      )
    },

    isEdcQr() {
      return this.paymentTypeSelected?.payment_type === 'edc_qr'
    },

    isCash() {
      return this.paymentTypeSelected?.payment_type === 'cash'
    },

    status() {
      return this.order?.data?.status
    },
    totalPrice() {
      return Number(this.order?.data?.total_price)
    },
    paidAmount() {
      let total = this.totalPrice

      // If with_plan
      if (this.isCanAddOntopDiscount) {
        total += Number(this.form.advance_payment)
        total += Number(this.form.advance_payment_optional)
      }

      let totalDiscount = total - this.totalDiscount

      return totalDiscount > 0 ? totalDiscount : 0
    },
    voucherDiscount() {
      let vouchersDiscount = 0

      Object.values(this.voucher?.data?.voucher_result ?? {}).filter(
        voucher => {
          if (voucher?.is_available) {
            vouchersDiscount += Number(voucher?.amount)
          }
        },
      )

      return vouchersDiscount ?? 0
    },
    totalDiscount() {
      return this.isCanAddOntopDiscount
        ? Number(this.form.ontop_discount)
        : this.isCanAddVoucher
        ? this.voucherDiscount
        : 0
    },
    branchCode() {
      return (
        this.order?.data?.staff_branch_code ??
        this.$cookies.get('bc') ??
        null
      )
    },
    isPayAtStoreCompleted() {
      return this.order?.data?.is_pay_at_store_completed ?? false
    },
    isCanConfirmPayment() {
      return (
        !this.isOrderLoading &&
        !this.isPayAtStoreCompleted &&
        this.order?.data?.is_pay_at_store &&
        this.status === ORDER_STATUS.PROCESS
      )
    },
  },

  data() {
    return {
      // Import
      colorBnn,

      // Data
      form: {
        order_uuid: this.$route.params.uid,

        order_type: 'general', // general | with_plan | with_tradein

        voucher_code: [''], // only with_tradein
        ontop_discount: null, // only with_plan
        advance_payment: null, // only with_plan
        rc_code: null, // only with_plan
        advance_payment_optional: null, // only with_plan

        pay_at_store_choice_id: null, // edc_credit_card | edc_qr | cash
        card_id: null, // only edc_credit_card | edc_qr
        edc_terminal: null, // only edc_credit_card | edc_qr
        edc_credit_card_no: null, // only edc_credit_card
        edc_comment: null, // only edc_credit_card | edc_qr

        approval_code: null,

        remark: null,

        staff_code: this.$cookies.get('sc') ?? null,
      },

      maxDiscount: 10000,

      deepLink: `${window.location.origin}/confirm-payment/${this.$route.params.uid}`,
    }
  },

  validations: {
    form: {
      order_type: {
        required,
      },
      pay_at_store_choice_id: {
        required,
      },
      card_id: {
        required: requiredIf(function() {
          return this.isEdcCreditCard || this.isEdcQr
        }),
      },
      edc_terminal: {
        required: requiredIf(function() {
          return this.isEdcCreditCard || this.isEdcQr
        }),
      },
      edc_credit_card_no: {
        required: requiredIf(function() {
          return this.isEdcCreditCard
        }),
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      edc_comment: {
        required: requiredIf(function() {
          return false
        }),
      },
      ontop_discount: {
        required: requiredIf(function() {
          return this.isCanAddOntopDiscount
        }),
        valid: function() {
          return this.isCanAddOntopDiscount
            ? this.form.ontop_discount <= this.maxDiscount
            : true
        },
      },
      advance_payment: {
        required: requiredIf(function() {
          return this.isCanAddOntopDiscount
        }),
        valid: function(value) {
          return this.isCanAddOntopDiscount
            ? Number(value) >= 0
            : true
        },
      },
      advance_payment_optional: {
        valid: function(value) {
          return this.isCanAddOntopDiscount
            ? Number(value) >= 0
            : true
        },
      },
      rc_code: {
        required: requiredIf(function() {
          return this.isCanAddOntopDiscount
        }),
      },

      voucher_code: {
        required: requiredIf(function() {
          return this.isCanAddVoucher
        }),
        valid: function() {
          return this.isCanAddVoucher ? this.isVoucherValid : true
        },
      },
      remark: {
        required: requiredIf(function() {
          return false
        }),
      },
    },
  },

  mounted() {
    this.fetch()
  },

  methods: {
    async fetch() {
      await this.getOrder()

      if (this.status !== ORDER_STATUS.PROCESS) {
        this.$router.push({
          name: 'Thank',
          params: { uid: this.$route.params.uid },
        })

        return
      }

      this.getEdcByBranch(this.branchCode)

      this.$store.commit('paymentType/setVerifyVoucherReset')
    },

    handleErrorMessage(field) {
      if (field === 'voucher_code') {
        return this.$v.form[field].$error
          ? 'กรุณาระบุ และตรวจสอบ Voucher Code อีกครั้ง'
          : ''
      }
      if (field === 'edc_credit_card_no') {
        return this.$v.form[field].$maxLength ||
          this.$v.form[field].$minLength
          ? 'กรุณาระบุเฉพาะ 4 ตัวท้าย'
          : this.$v.form[field].$error
          ? 'กรุณาระบุ'
          : ''
      }
      if (field === 'ontop_discount') {
        return this.$v.form[field].$error
          ? Number(this.form.ontop_discount) > this.maxDiscount
            ? `ส่วนลดเพิ่มเติมต้องเกิน ${this.$options.filters.currency2(
                this.maxDiscount,
              )}`
            : 'กรุณาระบุ'
          : ''
      }
      return this.$v.form[field].$error ? 'กรุณาระบุ' : ''
    },

    async getOrder() {
      const uid = this.$route.params.uid
      if (uid) {
        await this.$store.dispatch('order/getOrder', uid)

        if (this.order?.data) {
          localStorage.setItem(this.order?.data?.code, uid)
        }
      }

      this.$forceUpdate()
    },
    async getEdcByBranch(branchCode) {
      if (!branchCode) return

      await this.$store.dispatch(
        'paymentType/getEdcByBranch',
        branchCode,
      )
    },
    handleConfirmPayment() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        for (let input in this.$v.form) {
          if (!input.includes('$') && this.$v.form[input].$error) {
            const element = document.querySelector(
              `[data-pptr='${input}']`,
            )
            if (element)
              element.scrollIntoView({ behavior: 'smooth' })
            break
          }
        }

        return
      }

      this.$refs.confirmPaymentApprovalCode.open()
    },
    async handleSubmit(receiptNo) {
      this.$v.$touch()

      if (this.$v.$invalid) {
        for (let input in this.$v.form) {
          if (!input.includes('$') && this.$v.form[input].$error) {
            const element = document.querySelector(
              `[data-pptr='${input}']`,
            )
            if (element)
              element.scrollIntoView({ behavior: 'smooth' })
            break
          }
        }

        return
      }

      this.form.approval_code = receiptNo

      const payload = {
        order_uuid: this.form.order_uuid,
        order_type: this.form.order_type,
        pay_at_store_choice_id: this.form.pay_at_store_choice_id,

        ...((this.isEdcCreditCard || this.isEdcQr) && {
          card_id: this.form.card_id,
          edc_terminal: this.form.edc_terminal,
          edc_comment: this.form.edc_comment,

          approval_code: this.form.approval_code,
        }),

        ...(this.isEdcCreditCard && {
          edc_credit_card_no: this.form.edc_credit_card_no,
        }),

        ...(this.isCanAddOntopDiscount && {
          ontop_discount: Number(this.form.ontop_discount),
          advance_payment: Number(this.form.advance_payment),
          rc_code: this.form.rc_code,
          ...(this.form.advance_payment_optional && {
            advance_payment_optional: this.form
              .advance_payment_optional,
          }),
        }),

        ...(this.isCanAddVoucher &&
          this.isVoucherValid && {
            voucher_code: this.voucher.code,
          }),

        remark: this.form.remark,
        staff_code: this.form.staff_code,
      }

      try {
        await this.$store.dispatch('order/confirmPayment', payload)

        if (this.isSuccess) {
          localStorage.removeItem(this.order?.data?.code)

          this.$router.push({
            name: 'Thank',
            params: { uid: this.$route.params.uid },
          })
        }
      } catch {
        //
      }
    },
    handleMaxDiscount(event) {
      const total = Number(this.order?.data?.total_price)

      return (
        event < Number(total) ||
        `ส่วนลดเพิ่มเติมต้องไม่เกินยอดชำระเงิน ${this.$options.filters.currency2(
          total,
        )}`
      )
    },
  },

  watch: {
    form: {
      handler() {
        this.$v.form.$reset()
      },
      deep: true,
    },
  },
}
</script>

<style lang="stylus" scoped>

 .h-full {
	margin-top: 80px;
	min-height: 100dhv;
	display: flex;
	align-items: center;
	justify-content: center;
 }

 .hr-dash {
	overflow: hidden;
	position: relative;
	text-align: center;
	padding: 10px;
	margin-bottom: 20px;
	width: 50%;
 }

 .hr-dash:before {
	content: '';
	position: absolute;
	border-bottom: 5px dashed $color-gray-200;
	top: -3px;
	bottom: -3px;
	left: -3px;
	right: -3px;
 }

 .text-price-md
    font-size: 1rem
.text-price-lg
    font-size: 1.125rem
    font-weight: bold
    text-overflow: ellipsis

.action-confirm-container
  position: sticky
  bottom: 0
  left: 0
  width: 100%
  z-index: 8
  background-color: #fff
  border-top: 1px solid $color-gray-200


::v-deep .v-input input[type="number"]
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button
    -webkit-appearance: none;
    -moz-appearance: textfield;
.ontop-discount-container
  background-color: #f5f5f7
  border-radius: 8px
  padding 16px

.full-page
 overflow: visible !important
</style>
